<template>
  <div class="main-container">
    <v-toolbar
        flat
        tile
        class="box"
        style="background-color:transparent"
    >
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0">
        เพิ่มสินค้าใหม่
      </v-toolbar-title>
    </v-toolbar>

    <div class="mx-4 box box-2" style="min-height: 100%;">
      <v-row dense class="box box-2" style="height: 100%;display: flex;flex-direction: row">
        <v-col
            style="height: 100%"
        >
          <v-card
              style="height: 100%;overflow: auto"
              class="px-4 pt-4 rounded-lg elevation-2"
              outlined
              tile
          >
            <!--            {{$v.name.$error}}-->
            <!--            <p class="help is-danger" v-if="!$v.name.required">This field is required</p>-->
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >

              <v-row>
                <v-col cols="3" align-self="center">รหัสสินค้า</v-col>
                <v-col cols="5">
                  <v-text-field v-model="id_display" :rules="[rules.required]" required :disabled="!editable"
                                placeholder="กรอกข้อมูล"
                                outlined height="32px"
                                hide-details="auto"/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" align-self="center">ชื่อสินค้า</v-col>
                <v-col cols="5">
                  <v-text-field v-model="name" :rules="[rules.required]" required :disabled="!editable"
                                placeholder="กรอกข้อมูล"
                                outlined height="32px"
                                hide-details="auto"/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" align-self="center">รายละเอียดสินค้า</v-col>
                <v-col cols="5">
                  <v-text-field v-model="description" :disabled="!editable" placeholder="กรอกข้อมูล" outlined
                                height="32px"
                                hide-details="auto"/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" align-self="center">หน่วย</v-col>
                <v-col cols="5">
                  <v-text-field v-model="unit" :rules="[rules.required]" :disabled="!editable" placeholder="กรอกข้อมูล"
                                outlined
                                height="32px" hide-details="auto"/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" align-self="center">ราคาขายแบบลูกค้ามารับเอง</v-col>
                <v-col cols="5">
                  <v-text-field v-model="price_per_unit" :rules="[rules.required,rules.min]" :disabled="!editable"
                                placeholder="กรอกข้อมูล" suffix="บาท"
                                outlined height="32px" hide-details="auto" type="number"/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" align-self="center">ราคาขายแบบส่ง</v-col>
                <v-col cols="5">
                  <v-text-field v-model="price_for_ship" :rules="[rules.required,rules.min]" :disabled="!editable"
                                placeholder="กรอกข้อมูล" suffix="บาท"
                                outlined height="32px" hide-details="auto" type="number"/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" align-self="center">หมวดสินค้า</v-col>
                <v-col cols="5">
                  <v-select
                      :rules="[rules.required_select]"
                      outlined
                      dense
                      height="32px"
                      class="rounded mt-3"
                      hide-details="auto"
                      v-model="category"
                      :items="categorySelect"
                      placeholder="เลือกหมวดสินค้า"
                      item-text="name"
                      item-value="id"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" align-self="center">มีการคิดค่าตัก</v-col>
                <v-col cols="1" align-self="center">
                  <v-checkbox
                      class="mb-2 mt-0"
                      v-model="hasScoop"
                      hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" align-self="center">มีการส่งแบบเที่ยวรถ</v-col>
                <v-col cols="1" align-self="center">
                  <v-checkbox
                      class="mb-2 mt-0"
                      v-model="isTrip"
                      hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row v-if="isTrip">
                <v-col cols="3" align-self="center">ความหนาแน่น</v-col>
                <v-col cols="2">
                  <v-text-field
                      v-model="density"
                      placeholder="กรอกข้อมูล"
                      :rules="[mustHaveDensity]"
                      outlined height="32px"
                      hide-details="auto"
                      type="number">
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" align-self="center">% คอมมิชชั่น</v-col>
                <v-col cols="5">
                  <v-text-field v-model="commission_rate" :rules="[rules.required,rules.min]" :disabled="!editable"
                                placeholder="กรอกข้อมูล" suffix="%"
                                outlined height="32px" hide-details="auto" type="number"/>
                </v-col>
              </v-row>

              <v-row class="mt-4">
                <v-col align-self="center">
                  <span class="mr-2">Stock สินค้า</span>
                  <v-icon @click="addLotProduct" color="success">mdi-plus-circle</v-icon>
                </v-col>
              </v-row>

              <v-row v-for="(lot,index) in lots" :key="index" justify="space-between" class="mr-4">
                <v-col cols="8">
                  <v-row>
                    <v-col cols="6">
                      <v-text-field v-model="lot.location_name" :rules="[rules.required]" outlined
                                    placeholder="ใส่ชื่อของ Lot"
                                    height="32px"
                                    hide-details="auto"/>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field v-model="lot.amount" :rules="[rules.required,rules.min]" type="number" outlined
                                    placeholder="ใส่จำนวน" height="32px" hide-details="auto"/>
                    </v-col>
                    <v-icon @click="removeLot(index)" color="error">mdi-close-circle</v-icon>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="mt-6 mx-0">
                <v-btn @click="validate" :disabled="!valid" color="primary">เพิ่มสินค้า</v-btn>
              </v-row>

            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "CreateProduct",
  data() {
    return {
      valid: true,
      editable: true,
      id_display: '',
      name: '',
      description: '',
      unit: '',
      price_per_unit: 0,
      price_for_ship: 0,
      category: 0,
      isTrip: false,
      density: '',
      commission_rate: 0,
      categorySelect: [],
      hasScoop: false,
      lots: [],
      rules: {
        required: value => value.length > 0 || "ต้องกรอก",
        required_select: value => !!value || "เลือกหมวดสินค้า",
        min: value => value >= 0 || "จำนวนต้องเป็นตั้งแต่ 0 ขึ้นไป"
      },
    }
  },
  created() {
    this.$store.state.isLoading = false
    this.lots.push({location_name: this.name, amount: 0,})
    this.getCategory()
  },
  methods: {
    mustHaveDensity(value) {
      if (value.length === 0 || parseFloat(value) === 0) {
        if (this.isTrip) {
          return "ถ้าติ้ก มีการส่งแบบเที่ยวรถ จะต้องมีค่าความหนาแน่น (ตัวหาร)"
        }
        return true
      } else {
        return true;
      }
    },
    getCategory() {
      axios.get('/api/stocks/category/').then(res => {
        // res.data.data.forEach(c => this.categorySelect.push(c.name))
        this.categorySelect = res.data.data
      })
          .catch(e => {
            this.$store.state.snackbar = {
              text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
              visible: true,
              color: 'error'
            }
          })
    },
    async validate() {
      await this.$refs.form.validate()
      if (this.valid) {
        this.addToDatabase()
      }
    },
    addLotProduct() {
      this.lots.push({name: "", quantity: 0,})
    },
    removeLot(index) {
      if (this.lots.length > 1)
        this.lots.splice(index, 1)
    },
    addToDatabase() {
      let payload = {
        id_display: this.id_display,
        category: this.category,
        name: this.name,
        description: this.description,
        unit: this.unit,
        price: this.price_per_unit,
        price_for_ship: this.price_for_ship,
        hasCom: this.hasScoop,
        commission_rate: this.commission_rate,
        stock_location: this.lots,
        isTrip: this.isTrip,
        density: this.isTrip ? parseFloat(this.density) : 0,
      }
      axios.post('/api/stocks/product/', payload)
          .then(() => {
            // console.log(res)
            this.$store.state.snackbar = {
              text: 'เพิ่มสินค้าสำเร็จ',
              visible: true,
              color: 'success'
            }
            this.$router.push('/stock/product')
          })
          .catch(e => this.$store.state.snackbar = {
            text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
            visible: true,
            color: 'error'
          })
    }
  }
}
</script>

<style>
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 0;
  margin-bottom: 0 !important;
}
</style>